<template>
<div>
    <h1> {{ $t('storm-rated-by-users') }}</h1>
    <h2 class="mt-3 text-muted">{{$t('that-what-peoples-say')}}</h2>
    <b-row class="mt-5">

        <b-col>
            <h2 class="text-muted text-center">
                <b>86%  </b><br>
                {{$t('easy-to-use')}}
            </h2>
        </b-col>
        <b-col>
            <h2 class="text-muted text-center">
               <b> 77%  </b><br>
                {{$t('meets-requirements')}}
            </h2>
        </b-col>
        <b-col>
            <h2 class="text-muted text-center">
               <b> 94% </b><br>
                {{$t('support-and-help')}}
            </h2>
        </b-col>
   
    </b-row>
</div>
</template>

<script>
export default {
    name: 'FrontendUsersRating',

    data() {
        return {

        };
    },

    mounted() {

    },

    methods: {

    },
};
</script>

<style lang="scss" scoped>

</style>
